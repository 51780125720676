import Navbar from "../components/Navbar";
import Item from "../components/Item";
import contentEmbedded from "../content/contentEmbedded";
import Footer from "../components/Footer";
import Copyrights from "../components/Copyrights";
import { useEffect } from "react";

export default function Embedded() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const embedded = contentEmbedded.map((index) => {

    let dark = false;
    (index.id % 2) ? dark = false : dark = true;

    return (
      <Item
        key={index.id}
        dark={dark}
        title={index.title}
        source={index.source}
        poster={index.poster}
        video={index.video}
        image={index.image}
        subtitle={index.subtitle}
        description={index.description}
      />
    );
  });

  return (
    <>
      <Navbar />
      {embedded}
      <Footer/>
      <Copyrights/>
    </>
  );
}
