import "./Footer.css";
import logo from "../assets/logo.jpg";
import checkmark from "../assets/checkmark1.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// import AWS from "aws-sdk";

// AWS.config.update({
//   accessKeyId: "",
//   secretAccessKey: "",
//   region: "",
// });

export default function Footer() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChangeData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const checkmarkStyle = {
    display: isSubmitted ? "block" : "none",
    width: "50px",
    height: "50px",
    alignSelf: "center",
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const ses = new AWS.SES();
  //   const params = {
  //     Destination: {
  //       ToAddresses: ["mail.henriquemlima@gmail.com"], // Your email address where you want to receive the form data
  //     },
  //     Message: {
  //       Body: {
  //         Text: {
  //           Data:
  //             "Someone sent an email:\n" +
  //             `Name: ${data.name}\nEmail: ${data.email}\nMessage: ${data.message}\n`, // formData is the object containing the form data
  //         },
  //       },
  //       Subject: {
  //         Data: "Recruiter from your Portfolio Website", // Subject of the email
  //       },
  //     },
  //     Source: "mail.henriquemlima@gmail.com", // Your verified email address
  //   };

  //   ses.sendEmail(params, (err, data) => {
  //     if (err) {
  //       console.error(err);
  //     } else {
  //       console.log("Email sent successfully");
  //     }
  //   });

  //   setIsSubmitted(true);
  //   setTimeout(() => {
  //     setIsSubmitted(false);
  //     setData({
  //       name: "",
  //       email: "",
  //       message: "",
  //     });
  //   }, 4000);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://zycy22qkve.execute-api.us-east-2.amazonaws.com/send-email", data)
      .then((response) => {
        console.log(response);
        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
          setData({
            name: "",
            email: "",
            message: "",
          });
        }, 4000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <footer className="footer">
      <div className="contact">
        <h1>Contact Me</h1>
        <h2>Send me a message</h2>
        <form className="contact--form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Full Name"
            name="name"
            onChange={handleChangeData}
            value={data.name}
            required
          />
          <input
            type="email"
            placeholder="Your Email"
            name="email"
            onChange={handleChangeData}
            value={data.email}
            required
          />
          <textarea
            placeholder="Message"
            name="message"
            onChange={handleChangeData}
            value={data.message}
            required
          ></textarea>
          <button type="submit">Submit</button>
          <img src={checkmark} alt="Success" style={checkmarkStyle} />
        </form>
      </div>

      <div className="sitemap">
        <h1>Site Map</h1>
        <h2>Navigation</h2>

        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/Embedded">Embedded Systems</Link>
          </li>
          {/* <li>
            <Link to="/Mern">MERN Stack</Link>
          </li> */}
          <li>
            <Link to="/Robotics">Robotics</Link>
          </li>
          <li>
            <Link to="/Medical">Medical</Link>
          </li>
          <li>
            <Link to="/SolidWorks">SolidWorks</Link>
          </li>
        </ul>
      </div>
      <div className="info">
        <span className="logo--mobile">
          <img src={logo} alt="logo" className="logo" />
        </span>
        <div className="icon--div">
          <span className="icon">
            <FontAwesomeIcon icon={faPhone} style={{ color: "#000000" }} />
          </span>
          <a href="tel:+17406047890">+1 (740) 604-7890</a>
        </div>
        <div className="icon--div">
          <span className="icon">
            <FontAwesomeIcon icon={faEnvelope} style={{ color: "#000000" }} />
          </span>
          <a href="mailto:mail.henriquemlima@gmail.com">
            mail.henriquemlima@gmail.com
          </a>
        </div>
        <div className="icon--div">
          <span className="icon">
            <FontAwesomeIcon icon={faLinkedin} style={{ color: "#000000" }} />
          </span>
          <a
            href="https://www.linkedin.com/in/henriquemlima/"
            target="_blank"
            rel="noreferrer"
          >
            linkedin.com/in/henriquemlima/
          </a>
        </div>
        <div className="icon--div">
          <span className="icon">
            <FontAwesomeIcon icon={faGithub} style={{ color: "#000000" }} />
          </span>
          <a
            href="https://github.com/Neguete10"
            target="_blank"
            rel="noreferrer"
          >
            github.com/Neguete10
          </a>
        </div>
      </div>
    </footer>
  );
}
