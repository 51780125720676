import "./HardSkills.css";
import arduino from "../assets/logo-arduino.webp";
import mongo from "../assets/logo-mongo.gif";
import express from "../assets/logo-express.png";
import react from "../assets/logo-react.svg";
import node from "../assets/logo-node.png";
import solidworks from "../assets/logo-solidworks.png";

export default function HardSkills() {
  return (
    <div className="hard-skills">
      <div className="hard-skills-text">
        <h1>Technical Abilities</h1>
        <h2>
          Programming languages, frameworks, and development methodologies
        </h2>
      </div>
      <div className="hard-skills-bubbles">
        <img src={solidworks} alt="React" className="hard-skill-bubble"/>
        <img src={mongo} alt="React" className="hard-skill-bubble"/>
        <img src={express} alt="React" className="hard-skill-bubble"/>
        <img src={react} alt="React" className="hard-skill-bubble"/>
        <img src={node} alt="React" className="hard-skill-bubble"/>
        <img src={arduino} alt="React" className="hard-skill-bubble"/>
      </div>
    </div>
  );
}
