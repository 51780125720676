import "./AboutMe.css";
import drums from "../assets/drums.jpg";
import { useNavigate } from "react-router-dom";

export default function AboutMe() {

  const navigate = useNavigate();

  return (
    <div className="about-me">
      <img src={drums} alt="drums" className="about-me-photo-desktop" />
      <div className="about-me-text">
        <h1>About Me</h1>
        <h2>A Brief Introduction</h2>
        <p>
          Welcome to my portfolio! My name is Henrique Lima, and I am a Computer
          Engineer with a passion for Cloud-based solutions. Throughout my
          career, I have gained valuable experience in various fields, including
          Full Stack Development, Robotics and Embedded Systems. I believe that my work speaks for
          itself, and I am excited to share it with others who share my interest
          in technology and engineering. Whether you are a potential employer,
          collaborator, or simply someone who is interested in learning more
          about what I do, I hope that you find my portfolio informative and
          engaging.
        </p>
        <button className="btn" onClick={() => navigate("ReadMore")}>Read More</button>
      </div>
      <img src={drums} alt="drums" className="about-me-photo-mobile" />
    </div>
  );
}
