const contentMern = [
  {
    id: 1,
    title: "Secret Santa",
    source: "./videos/blackATS.jpeg",
    poster: "",
    video: false,
    image: true,
    subtitle:
      "Hobby Project - Secret Santa Website with React, Node, Express, and MongoDB",
    description:
      "This Secret Santa website makes holiday gift exchanges stress-free and fun. Sign-up and invite your participants to join. Our platform handles drawing names as well as emailing each participant. Make your holiday gift exchange memorable with our easy-to-use Secret Santa website.",
    link: "https://secretsanta.henriquemlima.com:3500/",
    },
  {
    id: 2,
    title: "Meme Generator",
    source: "./videos/trollFACE.png",
    poster: "",
    video: false,
    image: true,
    subtitle: "Hobby Project - Laughter is the best medicine",
    description:
      "Welcome to my meme generator website! The platform provides a simple way to create hilarious memes in just a few clicks. Choose from the library of popular meme templates. Add your own captions and customize it. Share your creations on social media and make your friends laugh out loud!",
    link: "https://memegenerator.henriquemlima.com:4000/",
    },
];

export default contentMern;
