import Hero from "../components/Hero";
import AboutMe from "../components/AboutMe";
import HardSkills from "../components/HardSkills";
import Footer from "../components/Footer";
import Copyrights from "../components/Copyrights";
import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero />
      <AboutMe />
      <HardSkills />
      <Footer/>
      <Copyrights/>
    </>
  );
}
