import "./Item.css";

export default function Item(props) {
  return !props.dark ? (
    <div className="about-me">
      {props.video && (
        <video
          className="about-me-photo-desktop"
          controls
          poster={require(`${props.poster}`)}
        >
          <source src={require(`${props.source}`)} type="video/mp4"></source>
          Your browser does not support the video tag.
        </video>
      )}

      {props.image && (
        <img
          className="about-me-photo-desktop"
          src={require(`${props.source}`)}
          alt="numb"
        ></img>
      )}

      <div className="about-me-text">
        <h1>{props.title}</h1>
        <h2>{props.subtitle}</h2>
        <p>{props.description}</p>
        {props.link && <a href={props.link} target="_blank" rel="noreferrer">Available on: {props.link}</a>}
      </div>

      {props.video && (
        <video
          className="about-me-photo-mobile"
          controls
          poster={require(`${props.poster}`)}
        >
          <source src={require(`${props.source}`)} type="video/mp4"></source>
          Your browser does not support the video tag.
        </video>
      )}

      {props.image && (
        <img
          className="about-me-photo-mobile"
          src={require(`${props.source}`)}
          alt="numb"
        ></img>
      )}
    </div>
  ) : (
    <div className="about-me-reverse">
      <div className="about-me-text-reverse">
        <h1>{props.title}</h1>
        <h2>{props.subtitle}</h2>
        <p>{props.description}</p>
        {props.link && <a href={props.link} target="_blank" rel="noreferrer">Available on: {props.link}</a>}
      </div>

      {props.video && (
        <video
          className="about-me-photo-desktop-reverse"
          controls
          poster={require(`${props.poster}`)}
        >
          <source src={require(`${props.source}`)} type="video/mp4"></source>
          Your browser does not support the video tag.
        </video>
      )}

      {props.image && (
        <img
          className="about-me-photo-desktop-reverse"
          src={require(`${props.source}`)}
          alt="numb2"
        ></img>
      )}
      {props.video && (
        <video
          className="about-me-photo-mobile"
          controls
          poster={require(`${props.poster}`)}
        >
          <source src={require(`${props.source}`)} type="video/mp4"></source>
          Your browser does not support the video tag.
        </video>
      )}

      {props.image && (
        <img
          className="about-me-photo-mobile"
          src={require(`${props.source}`)}
          alt="numb2"
        ></img>
      )}
    </div>
  );
}
