const contentSolidWorks = [
  {
    id: 1,
    title: "Spiritina - Cocktail Machine Design",
    source: "./videos/cadCocktail.png",
    poster: "./videos/cadCocktail.png",
    video: false,
    image: true,
    subtitle: "Hobby Project - Fusion 360",
    description: "I designed a cocktail machine called Spiritina using Fusion 360. The machine was a fusion of robotics and mixology, automating the process of mixing and dispensing cocktails. Spiritia featured a sleek, modern design with a user-friendly interface for selecting custom cocktail recipes. The project showcased my skills in SolidWorks and my ability to integrate robotics and electronics to create a unique and interactive experience."
    
  },
  {
    id: 2,
    title: "PCB Design - Weather Station",
    source: "./videos/pcb.png",
    poster: "./videos/pcb.png",
    video: false,
    image: true,
    subtitle: "Home Project - EasyEDA",
    description: "I designed a PCB for a weather station project using EasyEDA. The PCB integrated various sensors, including temperature, humidity, and pressure sensors, to collect weather data. The design included a microcontroller to process the data and an LCD display to show real-time weather information. The project demonstrated my proficiency in PCB design and my ability to create functional and efficient electronic systems."
  },
  {
    id: 3,
    title: "Truss Competition Design - 2° Place",
    source: "./videos/bridge.MOV",
    poster: "./videos/posterTruss.jpg",
    video: true,
    image: false,
    subtitle: "Fall 2021 - Cedarville University",
    description: "My team achieved second place at a truss competition. We designed and constructed a truss using limited resources, overcoming challenges through teamwork and problem-solving. Our truss held up impressively under weight testing, reflecting our hard work and dedication. The competition allowed us to use valuable engineering principles as well as communication and collaboration skills.",
  },
  {
    id: 4,
    title: "Wall Clock Design",
    source: "./videos/isometric.png",
    poster: "",
    video: false,
    image: true,
    subtitle: "Fall 2021 - Cedarville University",
    description: "Using SolidWorks, I designed a wall clock with a minimalist and modern style. The clock face was a simple circular shape with large, easy-to-read numbers. The hour and minute hands were slim and sleek. The clock body was made of brushed stainless steel, with a thin profile that hugged the wall. Overall, the design was elegant, functional, and easy to manufacture."
  },
];

export default contentSolidWorks;
