const contentEmbedded = [
  {
    id: 1,
    title: "Spiritina Cocktail Machine",
    source: "./videos/cocktail.MOV",
    poster: "./videos/cocktail.jpg",
    video: true,
    image: false,
    subtitle: "Hobby Project - A Fusion of Robotics and Mixology",
    description:
      "Introducing the Spiritina Cocktail Machine - a revolutionary project that combines robotics and mixology to create a unique and interactive cocktail experience. This project showcases the integration of robotics, electronics, and programming to automate the process of mixing and dispensing cocktails. By leveraging Arduino, servos, and sensors, the Spiritina Cocktail Machine can accurately measure and dispense precise amounts of ingredients to create a variety of cocktails. With a user-friendly interface and customizable recipes, users can easily select their desired cocktail and watch as the machine prepares it with precision and flair. This project offers a fun and engaging way to explore the intersection of technology and mixology while creating a memorable experience for users.",

  },
  {
    id: 2,
    title: "Wireless Hand Gesture Control for a 4WD Robot",
    source: "./videos/robothand2.mp4",
    poster: "./videos/robothand.jpg",
    video: true,
    image: false,
    subtitle: "Hobby Project - A Fusion of Computer Vision and Robotics",
    description:
      "Experience the future of robotics with my exciting personal project - a 4WD Robot controlled by Hand Gesture Recognition! Using Python and OpenCV, I developed a robust hand recognition system that accurately detects and tracks hand movements in real-time. By analyzing hand gestures, I translated them into precise instructions for the robot's movement. Through a reliable HTTP server, I established seamless wireless control, allowing the robot to flawlessly respond to my gestures in real-time. This project showcases the incredible potential of computer vision, machine learning, and wireless capabilities in robotics.",
  },
  {
    id: 3,
    title: "Endless Runner Game Design using the PIC18",
    source: "./videos/pic18.mp4",
    poster: "./videos/posterPIC18.jpg",
    video: true,
    image: false,
    subtitle: "Fall 2022 - Microcontrollers Class",
    description:
      "Run as far as you can while avoiding obstacles. Compete with your friends. Challenge yourself to beat your high score and become the ultimate endless runner! With each passing moment, the pace quickens, and the obstacles become more challenging. You must jump, slide, and dodge your way past various obstacles to stay alive and keep running.",
  },
  {
    id: 4,
    title: "HVAC Temperature Control",
    source: "./videos/TemperatureUno.jpg",
    poster: "",
    video: false,
    image: true,
    subtitle: "Hobby Project - Home Automation with Arduino Uno",
    description:
      "This project is a temperature control system for a home HVAC system. The system is controlled by an Arduino Uno and a 16x2 LCD display. The system is able to read the temperature from a temperature sensor and display it on the LCD display. The system is also able to control the temperature by turning on and off a fan and a heater. The system is able to turn on the fan when the temperature is too high and turn on the heater when the temperature is too low. The system is also able to turn off the fan and the heater when the temperature is just right.",
  },
  {
    id: 5,
    title: "Radar System",
    source: "./videos/RADAR.mp4",
    poster: "./videos/arduino.jpg",
    video: true,
    image: false,
    subtitle: "Hobby Project - Radar with Arduino Uno",
    description:
      "This project is a radar system that is able to detect objects within a 180 degree range. The system is controlled by an Arduino Uno integrated with Processing Software. The system is able to read the distance from an object using a ultrasonic sensor and display it on the laptop. The system is also able to detect the direction of the object using a servo motor.",
  },
  {
    id: 6,
    title: "Smart Home",
    source: "./videos/iotcloud.mp4",
    poster: "./videos/esp8266.jpg",
    video: true,
    image: false,
    subtitle: "Hobby Project - IoT Smart Bulb with ESP8266",
    description: "This is an innovative project that showcases the integration of ESP8266 to create an IoT-enabled smart bulb. This project provides a hands-on opportunity for electronics and programming enthusiasts to gain practical experience in IoT technology. By leveraging the ESP8266 board, the smart bulb can be connected to a WiFi network, enabling remote access and control of lighting settings. Through a user-friendly interface, users can conveniently adjust the light settings and receive real-time updates and notifications on their smartphone or computer. Overall, this project offers an excellent opportunity for individuals to explore the vast possibilities of IoT technology while creating a functional and innovative device.",
  },
];

export default contentEmbedded;
