import Navbar from "../components/Navbar";
import "./ReadMore.css";
import { useEffect } from "react";
import Footer from "../components/Footer";
import Copyrights from "../components/Copyrights";

import python from "../assets/python.png";
import languageC from "../assets/languageC.jpg";
import aws from "../assets/aws.png";
import javascript from "../assets/javascript.png";
import languageCSS from "../assets/languageCSS.png";
import Java from "../assets/java.png";


export default function ReadMore() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="read-more-text">
        <h1>Read More</h1>
        <p>
          Henrique Lima is a self-motivated, multilingual, Computer Engineer
          with a strong foundation in collaborative leadership. He has proven
          ability to understand user needs and work inside a project timeline to
          deliver effective software solutions using agile principles.
        </p>
        <p>
          Through his systematic study and research projects in school, Henrique
          has been able to practice writing concise but powerful code. Henrique
          is always adding new skills to his repertoire. Some of the
          professional skills that Henrique has used in various projects are:
        </p>
        <p>
          ➣ Programming Languages: Javascript, Python, Java, C++, Assembly <br />
          ➣ Web development: HTML5, CSS3, Next.js, Express.js, Docker, Figma <br />
          ➣ Cloud: AWS – EC2, RBS, IAM, Lambda, S3, Route53, Amplify <br />
          ➣ Databases: SQL (MySQL, PostgreSQL), NoSQL (MongoDB) <br />
          ➣ Wireshark: Network monitoring and packet auditing <br />
          ➣ Embedded Systems: PIC18, ATmega328P, ESP-8266, Raspberry Pi <br />
          ➣ Operating Systems: Windows, Linux <br />
          ➣ Version Control: Git, GitHub <br />
          ➣ Others: AutoCAD, SolidWorks, Unity <br />
          ➣ Soft Skills: Organizational Leadership, Critical Thinking, Problem Solving, Adaptability <br />
          ➣ Certifications: DevSecOps Fundamentals, Mayhem Certified <br />
        </p>
        <p>
          Relevant Course Work: Algorithms, Advanced Computer Architecture,
          Computer Networks, Microcontrollers, Advanced Digital Logic Design.
          <br />
        </p>
        <p>
          When he is not designing IoT for his house, you can find him hanging out
          with friends and visiting new places or jamming out on the drums to
          the latest summer pop hit!
        </p>
      </div>
      <div className="hard-skills">
        <div className="hard-skills-text">
          <h1>Technical Abilities</h1>
          <h2>
            Programming languages, frameworks, and development methodologies
          </h2>
        </div>
        <div className="hard-skills-bubbles">
          <img src={python} alt="React" className="hard-skill-bubble" />
          <img src={aws} alt="React" className="hard-skill-bubble" />
          <img src={javascript} alt="React" className="hard-skill-bubble" />
          <img src={languageCSS} alt="React" className="hard-skill-bubble" />
          <img src={Java} alt="React" className="hard-skill-bubble" />
          <img src={languageC} alt="React" className="hard-skill-bubble" />
        </div>
      </div>
      <Footer/>
      <Copyrights/>
    </>
  );
}
