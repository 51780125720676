import "./Hero.css";
import logo from "../assets/logo.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function Hero() {
  const [menu, setMenu] = useState(false);

  const handleClick = () => {
    setMenu(!menu);
  };

  const menu_bars = {
    color: "white",
    fontSize: "2.5rem",
    cursor: "pointer",
    padding: "10px",
  };

  return (
    <div className="hero">
      <div className="dropdown">
        <FontAwesomeIcon
          onClick={handleClick}
          icon={faBars}
          style={menu_bars}
        />
        {menu && (
          <div className="dropdown-content">
            <Link to="/">Home</Link>
            <Link to="/Embedded">Embedded</Link>
            {/* <Link to="/Mern">MERN Stack</Link> */}
            <Link to="/Robotics">Robotics</Link>
            <Link to="/Medical">Medical</Link>
            <Link to="/SolidWorks">CAD</Link>
          </div>
        )}
      </div>

      <nav className="hero--nav">
        <img src={logo} alt="logo" className="hero--logo" />
        <div className="hero--nav--btns">
          <Link to="/" className="nav-button active">
            Home
          </Link>
          <Link to="/Embedded" className="nav-button">
            Embedded
          </Link>
          {/* <Link to="/Mern" className="nav-button">
            MERN Stack
          </Link> */}
          <Link to="/Robotics" className="nav-button">
            Robotics
          </Link>
          <Link to="/Medical" className="nav-button">
            Medical
          </Link>
          <Link to="/SolidWorks" className="nav-button">
            CAD
          </Link>
        </div>
      </nav>
      <div className="hero--text">
        <h1>The Portfolio of Henrique Lima</h1>
        <h2>Innovative Computer Engineer</h2>
      </div>
    </div>
  );
}
