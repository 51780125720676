const contentMedical = [
  {
    id: 1,
    title: "ICBooster",
    source: "./videos/icbooster.png",
    poster: "",
    video: false,
    image: true,
    subtitle: "Startup - Artificial Intelligence in favor of the Doctor",
    description:
      "ICBooster is a software that uses artificial intelligence to help doctors in the diagnosis of diseases. The software is able to analyze the patient's medical records and provide the doctor with a list of possible diseases, as well as the probability of each one. The software is currently in test phase. The project aims to improve the quality of life of patients by providing doctors with a tool that can help them in the diagnosis of diseases. By signing up for more information, patients and healthcare providers can stay informed about the project's progress and potentially gain access to this groundbreaking technology in the future. Don't miss out on the opportunity to learn more about ICBooster and its potential benefits for patients.",
    link: "https://icbooster.com/",
    },
  {
    id: 2,
    title: "NeutroGuard",
    source: "./videos/neutroguard.png",
    poster: "",
    video: false,
    image: true,
    subtitle: "Startup Project - Artificial Intelligence for your well-being",
    description:
      "NeutroGuard's innovative software has the potential to revolutionize cancer patient care by predicting neutrophil counts non-invasively. Currently in test phase, the project aims to improve patients' quality of life by eliminating the need for needles and providing real-time data. By signing up for more information, patients and healthcare providers can stay informed about the project's progress and potentially gain access to this groundbreaking technology in the future. Don't miss out on the opportunity to learn more about NeutroGuard and its potential benefits for cancer patients.",
    link: "https://neutroguard.com/",
    },
];

export default contentMedical;
