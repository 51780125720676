const contentRobotics = [
  {
    id: 1,
    title: "VHDL - Advanced Digital Logic Design",
    source: "./videos/vhdl.jpg",
    poster: "",
    video: false,
    image: true,
    subtitle: "Spring 2024 - Cedarville University",
    description:
      "This project showcases my proficiency in VHDL and digital logic design. I developed a complex digital system that integrated multiple components, including counters, multiplexers, and state machines. By leveraging VHDL, I implemented efficient and optimized designs that met specific requirements and constraints. This project demonstrates my ability to apply advanced digital logic concepts to create functional and reliable digital systems."
  },
  {
    id: 2,
    title: "OBR - National Competition - 11° Place",
    source: "./videos/L4R4VGS.jpg",
    poster: "",
    video: false,
    image: true,
    subtitle: "2014 - High School Level - National Robotics Competition",
    description:
      "Robot designed with the purpose of completing a complex rescue mission, which includes several tasks like detecting and avoiding obstacles, finding and transporting objects, and locating a victim in a simulated disaster scenario. The robot competed against other teams and emerged in 11° place as it demonstrated good speed, accuracy, and efficiency in carrying out the assigned tasks.",
  },
  {
    id: 3,
    title: "OBR (Brazillian Olympiad of Robotics) - 1° Place",
    source: "./videos/OBR-CO.jpg",
    poster: "",
    video: false,
    image: true,
    subtitle: "2014 - High School Level - MidWest Robotics Competition",
    description:
      "Robot designed to navigate a complex rescue mission, completing tasks such as detecting and avoiding obstacles, locating and transporting objects, and finding a victim in a simulated disaster scenario. Winner against other teams after demonstrating robot's speed, accuracy, and efficiency.",
  },
  {
    id: 4,
    title: "LBR (Brasilia League of Robotics) - 1° Place",
    source: "./videos/LBR.mp4",
    poster: "./videos/posterLBR.jpg",
    video: true,
    image: false,
    subtitle: "2014 - High School Level - City Robotics Competition",
    description:
      "The line-following robot employs specialized sensors to detect and follow a line. With its robotic cage, it is capable of rescuing a package from one point to another with precision and accuracy. This innovative robot utilizes intelligent algorithms to navigate through the track and follow the line to reach the destination. Its robotic cage is designed to transport a package, providing a practical solution for automated package delivery.",
  },
];

export default contentRobotics;
