import "./Copyrights.css";


export default function Copyrights() {

    return (
        <div className="copyrights">
            <p>© 2024 - All Rights Reserved - Henrique Lima</p>
        </div>
    );
}