import "./Navbar.css";
import logo from "../assets/logo.jpg";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function Navbar() {
  const [menu, setMenu] = useState(false);
  const [activeButton, setActiveButton] = useState(-1);

  const navigate = useLocation();

  useEffect(() => {
    const currentPath = navigate.pathname;
    if (currentPath === "/") {
      setActiveButton(0);
    } else if (currentPath === "/Embedded") {
      setActiveButton(1);
    } else if (currentPath === "/Mern") {
      setActiveButton(2);
    } else if (currentPath === "/Robotics") {
      setActiveButton(3);
    } else if (currentPath === "/Medical") {
      setActiveButton(4);
    } else if (currentPath === "/SolidWorks") {
      setActiveButton(5);
    }
  }, [navigate.pathname]);

  const handleButtonClick = (index) => {
    setActiveButton(index);
  };

  const handleClick = () => {
    setMenu(!menu);
  };

  const menu_bars = {
    color: "white",
    fontSize: "2.5rem",
    cursor: "pointer",
    padding: "10px",
  };

  return (
    <div className="navbar">
      <div className="dropdown-nav-mobile">
        <FontAwesomeIcon
          onClick={handleClick}
          icon={faBars}
          style={menu_bars}
        />
        {menu && (
          <div className="dropdown-content-mobile">
            <Link to="/"> Home </Link>
            <Link to="/Embedded">Embedded</Link>
            {/* <Link to="/Mern">MERN Stack</Link> */}
            <Link to="/Robotics">Robotics</Link>
            <Link to="/Medical">Medical</Link>
            <Link to="/SolidWorks">CAD</Link>
          </div>
        )}
      </div>

      <nav className="nav-desktop">
        <img src={logo} alt="logo" className="navbar--logo" />
        <div className="nav-content-desktop">
          <Link
            to="/"
            className={activeButton === 0 ? "nav-button active" : "nav-button"}
            onClick={() => handleButtonClick(0)}
          >
            Home
          </Link>
          <Link
            to="/Embedded"
            className={activeButton === 1 ? "nav-button active" : "nav-button"}
            onClick={() => handleButtonClick(1)}
          >
            Embedded
          </Link>
          {/* <Link
            to="/Mern"
            className={activeButton === 2 ? "nav-button active" : "nav-button"}
            onClick={() => handleButtonClick(2)}
          >
            MERN Stack
          </Link> */}
          <Link
            to="/Robotics"
            className={activeButton === 3 ? "nav-button active" : "nav-button"}
            onClick={() => handleButtonClick(3)}
          >
            Robotics
          </Link>
          <Link
            to="/Medical"
            className={activeButton === 4 ? "nav-button active" : "nav-button"}
            onClick={() => handleButtonClick(4)}
          >
            Medical
          </Link>
          <Link
            to="/SolidWorks"
            className={activeButton === 5 ? "nav-button active" : "nav-button"}
            onClick={() => handleButtonClick(5)}
          >
            CAD
          </Link>
        </div>
      </nav>
    </div>
  );
}
