import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Embedded from "./pages/Embedded";
import Mern from "./pages/Mern";
import Robotics from "./pages/Robotics";
import Medical from "./pages/Medical";
import SolidWorks from "./pages/SolidWorks";
import ReadMore from "./pages/ReadMore";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Embedded" element={<Embedded />} />
        <Route path="/Mern" element={<Mern />} />
        <Route path="/Robotics" element={<Robotics />} />
        <Route path="/Medical" element={<Medical />} />
        <Route path="/SolidWorks" element={<SolidWorks />} />
        <Route path="/ReadMore" element={<ReadMore />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
